import { basket_channel } from "../../channels/basket_channel";
import dom from "../utils/dom";
let sidebasket = undefined;

function addToBasket(me){
  let product_id = dom.parentsWithClass("product-selection",me.target).getAttribute('product_id');
  basket_channel.add(product_id);
  //open basket
  if(sidebasket == undefined)
    sidebasket = M.Sidenav.getInstance(document.querySelector('.sideright'));
  sidebasket.open();
}

function initCarousel() {
  document.querySelectorAll('.carousel.carousel-slider.carousel-selection').forEach((e, i) => {
    M.Carousel.init(e, {
      fullWidth: true,
      indicators: true,
      noWrap: false
    });
  });
}

function load(){
  document.querySelectorAll(".btn-basket").forEach((e, i) => {
    e.addEventListener('click',addToBasket);
  });

  initCarousel();
}
document.addEventListener('turbolinks:load',load);

export default { load };
