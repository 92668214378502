import url from "./url";
import dom from "./dom";

function currentProgress(){
  if(document.querySelectorAll(".promotion").length > 1){
    return document.querySelector("#indicator-bar-"+document.querySelector("#promotions").getAttribute('pi'));
  } else {
    return undefined;
  }
}

function currentPromotionId(){
  return parseInt(document.querySelector("#promotions").getAttribute('pi'));
}

function jumpToIt(){
  url.jumpTo(document.querySelector("#promotion-"+currentPromotionId()).getAttribute('href'));
}

function slidePromotion() {
  if(document.querySelectorAll(".promotion").length > 1){
    let pid = currentPromotionId();
    slide(pid,document.querySelector("#promotion-"+pid.toString()).getAttribute('next'));
  }
}

let slide_on = true;
let cp = undefined;
const SECONDE = 1000;
const ETAPE = 20;
const CHANGE_EVERY_X = ETAPE*SECONDE;
let progress_interval = undefined;
function load() {
  document.querySelectorAll(".indicator").forEach((e, i) => {
    e.addEventListener('click',slideTo);
  });

  setSlideIntervals();
  document.querySelector("#promotions").addEventListener('click',jumpToIt);
  if(document.querySelector(".promotion")){
    cp = currentProgress();
    cp.parentElement.style.width = "100%";
    slide_on = true;
  } else {
    slide_on = false;
    cp = undefined;
  }
}
document.addEventListener('turbolinks:load', load);

//pause slide on hover
document.addEventListener('turbolinks:load', ()=>{
  let promo = document.querySelector("#promotions");
  promo.onmouseover = (hover_in) => {  slide_on = false;};
  promo.onmouseout = (hover_out) => { slide_on = true;};
});

function setSlideIntervals(){
  progress_interval = setInterval(function(){
    if(cp && slide_on){
      let pas = 5;
      let w = parseInt(cp.style.width.replace("%",""));
      cp.style.width = w+pas + "%";
      if(parseInt(cp.style.width.replace("%","")) > 100){
        resetProgress();
        slidePromotion();
        setProgress();
      }
    }
  },SECONDE);
}

function resetProgress(){
  cp.style.width = 0;
  cp.parentElement.style.width = "20%";
  cp = undefined;
}

function setProgress(){
  cp = currentProgress();
  cp.parentElement.style.width = "100%";
}

function slide(from, to){
  document.querySelector("#promotion-"+from.toString()).classList.remove("currentPromotion");
  document.querySelector("#promotion-"+to.toString()).classList.add("currentPromotion");
  document.querySelector("#promotions").setAttribute('pi',to);
}

function slideTo(me){
  me.stopImmediatePropagation();
  let indicator = me.target;
  if(!indicator.classList.contains("indicator")) indicator = dom.parents(".indicator",indicator);

  let next_pid = indicator.getAttribute('promo_id');
  let pid = currentPromotionId();

  if(pid != next_pid){
    clearInterval(progress_interval);
    resetProgress();

    slide(pid,indicator.getAttribute("promo_id"));

    setProgress();
    setSlideIntervals();
  }
}
