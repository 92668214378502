import { basket_channel } from "../channels/basket_channel";
const FROZEN_INPUT_TIME = 5000;

let sidebasket;
function initSideBasket() {
  let sidebasket = M.Sidenav.init(
    document.querySelector('.sideright'), {edge: 'right'});

  document.querySelector(".rightnav-trigger")
    .addEventListener('click',() => sidebasket.open());
  document.querySelector(".close-basket").addEventListener('click',
    ()=> {sidebasket.close()})
}
document.addEventListener('turbolinks:load', initSideBasket);

function removeBasketElement(me){
  basket_channel.remove(parseInt(me.target.getAttribute('beid')));
}
document.addEventListener('turbolinks:load',()=>{
  document.querySelectorAll(".remove-basket-element").forEach((e, i) => {
    e.addEventListener('click',removeBasketElement); });});

function changeInSizeRep(me){
  let value = parseInt(me.target.value);
  if(value >= 0){
    basket_channel.update_size_rep(me.target.getAttribute('beid'),
      me.target.getAttribute('key'), me.target.value);
  }
  if(value < 1) {
    me.target.value = '';
  }
  let size = 0;
  document.querySelectorAll(".size_rep_element_input").forEach((e,i)=> {
    let n = parseInt(e.value);
    if(!isNaN(n)) size += n;
  });
  let gtb = document.querySelector("#goToBasket");

  if(gtb.getAttribute('href').match("total=")){
    gtb.setAttribute('href', gtb.getAttribute('href').replace(/total=[0-9]*/, "total="+size));
  } else {
    gtb.setAttribute('href', gtb.getAttribute('href')+ "?total="+size)
  }
}
document.addEventListener('turbolinks:load',()=>{
  document.querySelectorAll(".size_rep_element_input").forEach((e, i) => {
    e.addEventListener('keyup',changeInSizeRep);
    e.addEventListener('paste',changeInSizeRep);
    e.addEventListener('change',changeInSizeRep);
  });});

function basketChange(data){
  if(data.size != undefined)
    document.querySelector(".basket-title").innerHTML = "Panier  ("+data.size+")";
  if(data.total != undefined)
    document.querySelector(".basket-total").innerHTML = data.total;

  if(data.action == "delete")
    document.querySelector("[beid=\""+data.beid+"\"].basket_row").remove();

  if(data.elements) {
    data.elements.forEach((e,i) => {
      Object.keys(e.size_rep).forEach((k) => {
        let input = document.querySelector("[beid=\""+e.beid+"\"][key=\""+k+"\"]");
        if(input.getAttribute("updated_at") + FROZEN_INPUT_TIME < Date.now()){
          if(e.size_rep[k] > 0){
            input.value = e.size_rep[k];
          } else {
            input.value = "";
          }
        }
      });
      document.querySelector("[beid=\""+e.beid+"\"].price").innerHTML = e.total;
    });
    M.updateTextFields();
  }
  if(data.action == "new"){
    let blc = document.querySelector(".basket_lines_container");
    blc.innerHTML = data.html + blc.innerHTML;
    let nb = document.querySelector(".basket_row[beid=\""+data.beid+"\"]");
    nb.querySelector(".remove-basket-element")
      .addEventListener('click',removeBasketElement);
    nb.querySelectorAll(".size_rep_element_input").forEach((e, i) => {
      e.addEventListener('change',changeInSizeRep);
      e.addEventListener('keyup',changeInSizeRep);
      e.addEventListener('paste',changeInSizeRep);
    });

  }
}
basket_channel.listenToChange(basketChange);

function emptyBasket(me){
  basket_channel.removeAll();
}
document.addEventListener('turbolinks:load',()=>{
  document.querySelector('#emptyBasket').addEventListener('click',emptyBasket); })
