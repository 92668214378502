import dom from "../utils/dom";

function findI(btn){
  if(btn.matches("i")) return btn;
  if(btn.matches(".text")) return btn.parentElement.querySelector("i");
  if(btn.querySelector("i")) return btn.querySelector("i");
}

function toggleFav(me){
  let product_id = dom.parents("[product_id]",me.target).getAttribute('product_id');
  if(findI(me.target).textContent == "favorite"){
    basket_channel.unfav(product_id);
  } else {
    basket_channel.fav(product_id);
  }
}

function favChange(data){
  let btn = document.querySelector("[product_id=\""+data.product_id+"\"] .btn-fav");

  let i = findI(btn);
  let text = btn.querySelector(".text");

  if(data.action == "del_fav"){
    i.textContent = 'favorite_border';
    if(text)text.textContent = text.getAttribute('off');
  } else if (data.action == "new_fav"){
    i.textContent = 'favorite';
    if(text)text.textContent = text.getAttribute('on');
  }
}

let basket_channel;
function load(bc){
  basket_channel = bc;
  document.querySelectorAll(".btn-fav").forEach((e, i) => {
    e.removeEventListener('click', toggleFav);
    e.addEventListener('click', toggleFav);
  });
  basket_channel.listenToFav(favChange);
}
export default { load };
