function parentsTable(me){
  return parentsType("TABLE",me);
}

function parentsWithClass(className, me){
  if(me.parentNode == null) return me;
  if(me.classList.contains(className)) return me;
  return parentsWithClass(className, me.parentNode);
}

function parentsType(type,me){
  if(me.parentNode == null) return me;
  if(me.nodeName == type) return me;
  return parentsType(type,me.parentNode);
}

function parents(selector,me){
  if(me.parentNode == null) return me;
  if(me.matches(selector)) return me;
  return parents(selector,me.parentNode);
}

function before(htmlStr,node){
  node.parentNode.insertBefore(create(htmlStr), node);
}

function create(htmlStr) {
    var frag = document.createDocumentFragment(),
        temp = document.createElement('div');
    temp.innerHTML = htmlStr;
    while (temp.firstChild) {
        frag.appendChild(temp.firstChild);
    }
    return frag;
}

export default {
  parentsTable,parentsWithClass,parentsType, parents, before
}
