const haml = require('hamljs');
import inp from 'raw-loader!./views/size_repartition_inputs.haml';

function strSizeCatToEmptyRep(str){
  let rep = {};
  str.split(",").forEach(function(el){
    rep[el.replace(" ","")]=0;
  });
  return rep;
}

function total(rep){
  let t = 0;
  Object.values(rep).forEach(function(v){ t+=v });
  return t;
}

function toHtml(rep,pid){
  let asrep = [];
  Object.keys(rep).forEach(function(k){asrep.push({key: k, value: rep[k]})});
  return haml.render(inp, {"locals": {"items": asrep, "pid": pid}});
}

export default {
  strSizeCatToEmptyRep,total,toHtml
}
