function onCycleTo(me){
  document.querySelectorAll(".product-promotion").forEach((e, i) => {
    e.style.display = "none";
  });

  document.querySelectorAll(".product-promotion-for-" +
    me.getAttribute('promo_id')).forEach((e, i) => {
      e.style.display = "block";
    });
}

document.addEventListener('turbolinks:load',function(){
  M.Carousel.init(document.querySelector(".promotions-slider"),{
    fullWidth: true,
    indicators: false,
    onCycleTo: onCycleTo
  });
  document.querySelectorAll(".promotions-slider").clientHeight = 150;
})
